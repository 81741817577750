import { PlusCircleIcon } from "@heroicons/react/solid"
import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"

export const AtkAddButton = (
  props: Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "className"
  >
) => {
  return (
    <Link to="/atk/add">
      <button
        {...props}
        className={clsx(
          `w-full flex justify-center py-3 px-4 border border-transparent shadow-md text-md font-medium bg-orange-600 hover:bg-orange-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 items-center`,
          "disabled:opacity-75 disabled:bg-gray-300 hover:bg-gray-500 disabled:text-gray-600 disabled:cursor-not-allowed disabled:border-gray-200 disabled:shadow-none"
        )}
      >
        <PlusCircleIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-inherit"
          aria-hidden="true"
        />
        บันทึกผลตรวจ
      </button>
    </Link>
  )
}
