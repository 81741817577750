import React, { VFC } from "react"
import { AtkResult } from "../../generated/graphql"
import { AtkListItem } from "./AtkListItem"

export interface AtkListProps {
  data: AtkResult[]
  onDelete?: (data: AtkResult) => void
}

export const AtkList: VFC<AtkListProps> = ({ data, onDelete }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {data.length ? (
          data.map((item) => (
            <AtkListItem key={item.id} data={item} onDelete={onDelete} />
          ))
        ) : (
          <li>
            <div className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <p className="text-md font-semibold text-red-700">
                  ไม่มีผลตรวจ ATK
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}
