import React, { useCallback } from "react"

import PageLoading from "../PageLoading"
import { useSession } from "../../hooks/useSession"
import { AtkList } from "./AtkList"
import {
  AtkResult,
  OrderBy,
  useDeleteAtkResultByPkMutation,
  useGetAtkResultsQuery,
} from "../../generated/graphql"
import toast from "react-hot-toast"
import { isBrowser } from "../../libs"

export const UserAtkList = () => {
  const { loading: sessionLoading, isAuthenticated } = useSession()
  const {
    data,
    loading: vaccineLoading,
    refetch,
  } = useGetAtkResultsQuery({
    skip: !isAuthenticated,
    ssr: false,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: {
      order_by: [
        {
          date: OrderBy.DescNullsLast,
        },
      ],
    },
  })

  const [deleteFn, { loading: deleting }] = useDeleteAtkResultByPkMutation({
    onCompleted: () => {
      refetch()
      toast.success("ลบข้อมูลสำเร็จ")
    },
  })

  const handleDelete = useCallback(
    (row: AtkResult) => {
      if (isBrowser() && window.confirm("ยืนยันการลบข้อมูล")) {
        deleteFn({
          variables: {
            id: row.id,
          },
        })
      }
    },
    [deleteFn]
  )

  const loading = sessionLoading || vaccineLoading

  if (!data?.returning && loading) {
    return <PageLoading />
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="px-4 py-5 pb-3 sm:px-6 ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          ผลตรวจ ATK
        </h3>
      </div>
      <AtkList data={data?.returning || []} onDelete={handleDelete} />
    </div>
  )
}
