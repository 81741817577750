import React from "react"
import Layout from "../../components/layout/Layout"
import { Helmet } from "react-helmet"
import { useRequireAuth } from "../../hooks/useRequireAuth"
import { NoSsr } from "../../components/NoSsr"
import { UserProfileCard } from "../../components/user"
import { AtkAddButton } from "../../components/atk/AtkAddButton"
import { UserAtkList } from "../../components/atk/UserAtkList"
import { VaccineNotSupportAnonymous } from "../../components/vaccine/VaccineNotSupportAnonymous"
import { useSession } from "../../hooks/useSession"

const Atk = () => {
  useRequireAuth()

  const { type } = useSession()

  return (
    <Layout>
      <Helmet>
        <title>ผลตรวจ ATK - GoFight | KMITL</title>
      </Helmet>
      <NoSsr>
        <VaccineNotSupportAnonymous />
        <UserProfileCard />
        <div className="mt-4">
          <AtkAddButton disabled={type !== "kmitl"} />
          {type !== "kmitl" ? (
            <div className="text-red-400 text-center mt-1">
              ขณะนี้ ระบบยังไม่รองรับบุคคลภายนอก
            </div>
          ) : null}
        </div>
        <div className="mt-4">
          <UserAtkList />
        </div>
      </NoSsr>
    </Layout>
  )
}

export default Atk
