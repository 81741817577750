import React, { VFC } from "react"
import {
  CalendarIcon,
  CheckCircleIcon,
  DocumentIcon,
  ExclamationIcon,
  PhotographIcon,
  TrashIcon,
} from "@heroicons/react/solid"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import { AtkResult } from "../../generated/graphql"
import { DateUtils } from "../../libs/date"
import { format, parse } from "date-fns"

export interface AtkListItemProps {
  data: AtkResult
  onDelete?: (data: AtkResult) => void
}

const InfectionBadge = ({ value }: { value?: boolean }) => {
  if (value) {
    return (
      <div className="flex items-center">
        <ExclamationIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
          aria-hidden="true"
        />
        <p className="font-medium text-red-600 truncate text-left pl-0.5">
          พบเชื้อ
        </p>
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <CheckCircleIcon
        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
        aria-hidden="true"
      />
      <p className="font-medium text-green-600 truncate text-left pl-0.5">
        ไม่พบเชื้อ
      </p>
    </div>
  )
}

function toTime(time: string) {
  // replace +00 เพื่อไม่ได้ timezone
  return format(parse(time.replace("+00", ""), "HH:mm:ss", new Date()), "HH:mm")
}

export const AtkListItem: VFC<AtkListItemProps> = ({ data, onDelete }) => {
  return (
    <li>
      <div className="block hover:bg-gray-50">
        <div className="px-4 py-4 flex items-center sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:justify-between">
            <div className="w-full">
              <div className="flex justify-between">
                <div>
                  <div className="flex items-center text-md text-gray-800">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {DateUtils.toThaiDate(new Date(data.date))}{" "}
                    {toTime(data.time)}
                  </div>
                </div>
                <div>
                  <div className="flex">
                    <TrashIcon
                      className="flex-shrink-0 mr-1.5 h-6 w-6 text-red-500 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => onDelete?.(data)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <InfectionBadge value={data.infected} />
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-md text-gray-800">
                  <DocumentIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="pl-0.5">Brand {data.brand_name}</span>
                </div>
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-md text-gray-800">
                  <PhotographIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Zoom>
                    <img
                      src={data.image}
                      className="max-h-12"
                      alt={`รูปผลตรวจ ATK วันที่ ${DateUtils.toThaiDate(
                        new Date(data.date)
                      )}`}
                    />
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}
